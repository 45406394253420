import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { UIErrorMode } from "../hooks/useErrorModes";

// TODO: Move to ENV and setup proper SA
export function getServiceAccount() {
  console.log("ENV", { env: process.env.VITE_ENV });
  if (process.env.VITE_ENV === "production") {
    return {
      apiKey: "AIzaSyAi3Szv418prdKlm8zfyJL30d9_BOfXANE",
      authDomain: "exact-shop.firebaseapp.com",
      projectId: "exact-shop",
      storageBucket: "exact-shop.appspot.com",
      messagingSenderId: "969819592348",
      appId: "1:969819592348:web:ca85e088e03310d4c2ff59",
    };
  }

  return {
    apiKey: "AIzaSyDO-GvsQ0quDCr3KhcU-TlMTIF29U64jTo",
    authDomain: "exact-shop-dev.firebaseapp.com",
    projectId: "exact-shop-dev",
    storageBucket: "exact-shop-dev.appspot.com",
    messagingSenderId: "601253801586",
    appId: "1:601253801586:web:c9f7bac7cb19949eacdceb",
  };
}

export const app = initializeApp(getServiceAccount());
export const auth = getAuth(app);
export const db = getFirestore(app);

if (process.env.VITE_ENV === "development" || process.env.VITE_ENV === "test") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

type Config = {
  maintenanceMode?: boolean;
  maintenanceModeExclusions?: string[];
  technicalDifficultiesMode?: boolean;
};

export const getErrorMode = async (
  email?: string | null
): Promise<UIErrorMode> => {
  let modes: UIErrorMode = {
    maintenance: false,
    technicalDifficulties: false,
  };

  const configDoc = doc(db, "config", "frontend");
  const docSnap = await getDoc(configDoc);
  const data: Config = docSnap.data() as Config;

  modes.maintenance = data?.maintenanceMode ?? false;
  modes.technicalDifficulties = data?.technicalDifficultiesMode ?? false;

  if (email && data?.maintenanceModeExclusions?.includes(email)) {
    modes.maintenance = false;
  }

  return modes;
};

if (process.env.VITE_ENV === "test") {
  createUserWithEmailAndPassword(auth, "john@zzapps.nl", "asdasd")
    .catch(_ => null)
    .finally(() => {
      signInWithEmailAndPassword(auth, "john@zzapps.nl", "asdasd");
    });
}
